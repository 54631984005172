// Header.tsx
import React, { useContext } from 'react';
import './index.css'; // Importando o arquivo CSS
import { useTheme } from '../../context/theme.context';
import { EmpresaContext } from '../../context/empresa.provider';

// Defina os tipos das props, caso existam props
interface FooterProps { }

const Footer: React.FC<FooterProps> = () => {

  const { currentTheme } = useTheme();
  const { dadosEmpresa } = useContext(EmpresaContext);
  return (
    <footer>
      <div className="footer-columns">
        <div className="footer-column">
          {
            dadosEmpresa?.sobre_a_empresa && (<>
              <h3 style={{ color: currentTheme.color.primary }}>Sobre a empresa</h3>
              <p> {dadosEmpresa.sobre_a_empresa} </p>
            </>
            )
          }
        </div>
        <div className="footer-column">
          <h3 style={{ color: currentTheme.color.primary }}>Navegue por</h3>
          <ul>
            <li><a href="/home" style={{ color: currentTheme.color.primary }}>Página Inicial</a></li>
            <li><a href="/panel" style={{ color: currentTheme.color.primary }}>Meus Pedidos</a></li>
            <li><a href="/checkout" style={{ color: currentTheme.color.primary }}>Meu Carrinho</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3 style={{ color: currentTheme.color.primary }}>Fale conosco</h3>
          {dadosEmpresa?.telefone_contato && (
            <p>{dadosEmpresa.telefone_contato}</p>
          )
          }
        </div>
        <div className="footer-column">
          <h3 style={{ color: currentTheme.color.primary }}>Redes sociais</h3>
          <ul className="social-media">
            {dadosEmpresa?.link_facebook && (
              <>
                <li><a href={dadosEmpresa.link_facebook} style={{ color: currentTheme.color.primary }}><i className="fab fa-facebook"></i> Facebook</a></li>
              </>
            )}
            {dadosEmpresa?.link_instagram && (
              <>
                <li><a href={dadosEmpresa.link_instagram} style={{ color: currentTheme.color.primary }}><i className="fab fa-instagram"></i> Instagram</a></li>
              </>
            )}
          </ul>

        </div>
      </div>
      <div className="footer-legal" style={{ backgroundColor: currentTheme.color.primary }}>
        {
          dadosEmpresa?.texto_rodape && (
            <p>{dadosEmpresa.texto_rodape}</p>
          )
        }
      </div>
    </footer>
  );
};

export default Footer;