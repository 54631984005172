import { Outlet } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import { CartProvider } from './context/cart.provider.tsx';
import { ProductProvider } from './context/product.provider.tsx';
import { ThemeProvider } from './context/theme.context.tsx';
import { RepresentadaProvider } from './context/representada.provider.tsx';
import { EmpresaContext, EmpresaProvider } from './context/empresa.provider.tsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useContext, useEffect } from 'react';
import { BASE_URL } from './services/api.ts';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <EmpresaProvider>
          <MetaTagUpdater /> {/* Adicione o componente para atualizar meta tags */}
          <RepresentadaProvider>
            <ProductProvider>
              <CartProvider>
                <Outlet />
              </CartProvider>
            </ProductProvider>
          </RepresentadaProvider>
        </EmpresaProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}


const MetaTagUpdater = () => {
  const { dadosEmpresa, getDadosEmpresa } = useContext(EmpresaContext);

  useEffect(() => {
    if (!dadosEmpresa) {
      getDadosEmpresa();
    }
  }, [dadosEmpresa, getDadosEmpresa]);

  const faviconUrl = dadosEmpresa
    ? `${BASE_URL}uploads/${dadosEmpresa?.imagem}` // Caminho dinâmico para o .jpeg
    : "/favicon.ico"; // Fallback para o favicon padrão
  return (
    <Helmet>
      <title>
        {dadosEmpresa
          ? `${dadosEmpresa.nome} - E-commerce`
          : "E-Commerce"}
      </title>
      <meta
        name="description"
        content={
          dadosEmpresa
            ? `${dadosEmpresa.nome} E-commerce`
            : "E-Commerce"
        }
      />
      <link rel="icon" type="image/jpeg" href={faviconUrl} />
    </Helmet>
  );
};


export default App