import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useNavigate } from "react-router-dom";
import { LoginInputProps, loginSchema } from "../../components/schemas/user.schemas";
import useAuthStore from "../../context/auth.store";
import './login.css';
import ImageComponent from '../../components/shared/image.shared.component';


import Component from '../../components/components';
import { useState, useEffect } from 'react';
import api, { BASE_URL } from '../../services/api';
const Login = () => {

  const { login, error,clearError } = useAuthStore()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [logo, setLogo] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }

  } = useForm<LoginInputProps>({ resolver: zodResolver(loginSchema) })


  useEffect(() => {
    // Reseta o erro ao montar a tela novamente
   
    clearError();

     api.get('/Empresas/logo')
            .then(response => {
                setLogo(response.data.logo); // Define o logotipo recebido no estado
                console.log(response);
            })
            .catch(error => {
                console.error('Erro ao buscar o logotipo:', error);
            });
}, []);

  const onSubmit = async (data: LoginInputProps) => {
    setLoading(true);  // Start loading
    const auth = await login(data);
    
    if (auth)
      navigate('/home')
    setLoading(false);  // Start loading
  }


  return (
    <div className='main'>
      <div className="centered-content">
        {loading && <Component.Shared.LoaderComponent overlay />}
        <form className="form-control contentLogin" onSubmit={handleSubmit(onSubmit)}>
          <ImageComponent  className='' source= {`${BASE_URL}uploads/${logo}`} margin={50} height={114} width={114}  />
          <Component.Shared.TextInputComponent  onChange={(e) => setValue('email',e.target.value)}   margin={0} marginTop={10} size={16} color="black" placeholder="E-mail" type="text" register={register('email')} />

          {errors.email ? (
            <Component.Shared.TextComponent  value={errors.email.message} size={12} color='red' margin={0} align='flex-start' />

          ) : null}
          <Component.Shared.TextInputComponent  onChange={(e) => setValue('password',e.target.value)}  margin={0} marginTop={10} size={16} color="black" placeholder="Senha" type="password" register={register('password')} />
          {errors.password ? (

            <Component.Shared.TextComponent value={errors.password.message} size={12} color='red' margin={0} align='flex-start' />
          ) : null}
          {error ? (
            <Component.Shared.TextComponent  value={error} size={12} color='red' margin={0} align='flex-start' />
          ) : null}


          <button className="form-control btn btn-primary button" type='submit'>Acessar</button>

{
          <div className='bottom'>
            {/*  <Link
              to={'/forgot-password'}
              className='nav-link'>
              Esqueci minha senha
            </Link>  */} 
            <Link
              to={'/solicitar-acesso'}
              className='nav-link'>
              Solicitar acesso
            </Link>
          </div>
}
        </form>
      </div>
    
      <div className="footer-sistema">
        <p>Inovando Sistemas - Todos os direitos reservados.</p>
      </div>
    </div>

  )
}

export default Login


