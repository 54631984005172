import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {  SolicitarAcessoInputProps, solicitarAcessoSchema } from "../../components/schemas/user.schemas";
import './index.css';
import ImageComponent from '../../components/shared/image.shared.component';
import Component from '../../components/components';
import { useEffect, useState } from 'react';
import api, { BASE_URL } from '../../services/api';

const SolicitarAcesso = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);
    const [sucess, setSucess] = useState(false);
    const [logo, setLogo] = useState('');
    useEffect(() => {
        // Reseta o erro ao montar a tela novamente
        clearError();

        api.get('/Empresas/logo')
            .then(response => {
                setLogo(response.data.logo); // Define o logotipo recebido no estado
                console.log(response);
            })
            .catch(error => {
                console.error('Erro ao buscar o logotipo:', error);
            });
    }, []);

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<SolicitarAcessoInputProps>({ resolver: zodResolver(solicitarAcessoSchema), defaultValues: { tipo: 'pf' } })


    const onSubmit = async (data: SolicitarAcessoInputProps) => {
        setSucess(false);
        setLoading(true);  // Start loading
     
      
        api.post('/usuarios/solicitar_acesso', data)
        .then(response => {
            setSucess(true);
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
                // Captura a mensagem do erro
                const errorMessage = error.response.data.message;
                setError(errorMessage)
                // Aqui você pode tratar o erro conforme necessário
            } else {
                setError(error)
            }
        });

        setLoading(false);  // Start loading

    
    }

    const clearError = async () => {
        try {
          setError(null);
        } catch (error) {
          console.error(error)
        }
      }
    const handlePersonTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Limpa o campo 'cnpj_cpf' ao trocar o tipo de pessoa
        setValue('cnpj_cpf', '');
        setValue('tipo', e.target.value);
    };

    return (
        <div className="main">

            <div className="centered-content">
                {loading && <Component.Shared.LoaderComponent overlay />}
                <form className="form-control contentLogin" onSubmit={handleSubmit(onSubmit)}>
                    <ImageComponent className='' source={`${BASE_URL}uploads/${logo}`} margin={50} height={114} width={114} />

                    <Component.Shared.TextComponent marginTop={0} value="SOLICITAR ACESSO" size={14} color='black' margin={0} align='center' />

                    <div className='form-check' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <input type="radio" value="pf" {...register('tipo')} defaultChecked onChange={handlePersonTypeChange} /> Pessoa Física
                        <div style={{ marginLeft: '50px' }}></div> {/* Espaço entre os botões */}
                        <input type="radio" value="pj" {...register('tipo')} onChange={handlePersonTypeChange} /> Pessoa Jurídica
                    </div>

                    {watch('tipo') === 'pf' ? (
                        <>
                            <Component.Shared.TextInputComponent mask='cpf' margin={0} marginTop={10} maxLength={14} size={16} color="black" placeholder="* CPF" type="text" register={register('cnpj_cpf', { required: 'CPF é obrigatório' })} /></>
                    ) : (
                        <>
                            <Component.Shared.TextInputComponent margin={0} marginTop={10} mask='cnpj' maxLength={18} size={16} color="black" placeholder="* CNPJ" type="text" register={register('cnpj_cpf', { required: 'CNPJ é obrigatório' })} /></>
                    )}
                    {errors.cnpj_cpf ? (<Component.Shared.TextComponent value={errors.cnpj_cpf.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    <Component.Shared.TextInputComponent margin={0} marginTop={10} size={16} color="black" placeholder="* E-mail para acessar o ecommerce" type="text" register={register('email')} />
                    {errors.email ? (
                        <Component.Shared.TextComponent value={errors.email.message} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    {error ? (
                        <Component.Shared.TextComponent value={error} size={12} color='red' margin={0} align='flex-start' />
                    ) : null}

                    {sucess ? (
                        <Component.Shared.TextComponent value={'Solicitação concluída com sucesso. Conclua no seu e-mail.'} size={12} color='green' margin={0} align='flex-start' />
                    ) : null}
                    <button className="form-control btn btn-primary button" type='submit'>Solicitar acesso</button>

                </form>

            </div>
            <div className="footer-sistema-create">
                <p>Inovando Sistemas - Todos os direitos reservados.</p>
            </div>
        </div>

    )
}

export default SolicitarAcesso;


