import React from 'react';
import ClienteCard from './clientecard.shared.component';
import Client from '../../types/client.type';

interface ListCardProps {
  clientes: Client[];
  onSelectCliente?: (cliente: Client) => void; 
  habilita_cria_acesso: boolean;
  onClienteClick?: (cliente: Client) => void; // Callback para o clique
}
const ListaClienteCard: React.FC<ListCardProps> = ({ clientes, onSelectCliente ,habilita_cria_acesso,onClienteClick}) => {
  
  return (
    <div>
      {clientes.map(cliente => (
       <div onClick={() => cliente.inativar === false ? onSelectCliente?.(cliente) : null} key={cliente.id}>
          <ClienteCard    onCriarAcessoClick={onClienteClick} cliente={cliente} habilita_cria_acesso={habilita_cria_acesso}    />
        </div>
      ))}
    </div>
  );
};

export default ListaClienteCard;