import { ReactNode, createContext, useCallback, useState } from "react";
import Empresa from "../types/empresa.type";
import api from "../services/api";


interface EmpresaContextProps {
  dadosEmpresa: Empresa | null,
  getDadosEmpresa: () => Promise<Empresa | null>;
}


export const EmpresaContext = createContext<EmpresaContextProps>({
  dadosEmpresa: null,
  getDadosEmpresa: async () => null,
});

export const EmpresaProvider = ({ children }: { children: ReactNode }) => {
  // ...outros estados e funções
  const [dadosEmpresa, setEmpresa] = useState<Empresa | null>(null);
 
  const getDadosEmpresa = useCallback(async () => {
    try {

      const url = `/empresas/dados_tela_inicial`;
   
      const response = await api.get(url);
      setEmpresa(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching empresa:', error);
      return [];
    }
  }, []);

  return (
    <EmpresaContext.Provider value={{ 
      // ...outros estados e funções passadas aqui
      dadosEmpresa, 
      getDadosEmpresa 
    }}>
      {children}
    </EmpresaContext.Provider>
  );
};