import { useEffect, useState } from "react";
import api from "../../../../services/api";
import Component from "../../../components";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import "./index.css";
import Client from "../../../../types/client.type";
import Swal from "sweetalert2";

const Clientes: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [list, setList] = useState<Client[]>([])
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    useEffect(() => {

        gerarRelatorio();
    }, []);

    const gerarRelatorio = async () => {


        setIsLoading(true);
        await api.get('/relatorios/clientes'
        ).then(response => {
            setList(response.data)
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })

    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Filtra clientes com base no termo de busca
    const filteredClientes = list.filter(cliente =>
        cliente.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cliente.cnpj_cpf.includes(searchTerm)
    );
    const handleClienteClick = (cliente: Client) => {

        if (cliente.email === null || cliente.email === '') {
            Swal.fire({
                title: 'Erro',
                text: 'Cliente sem e-mail cadastrado.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#d33'
            });
            return;
        }


        api.post('/usuarios/solicitar_acesso', { cnpj_cpf: cliente.cnpj_cpf, email: cliente.email })
            .then(response => {

                Swal.fire({
                    title: 'OK',
                    text: 'Solicitação concluída com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#d33'
                });
            })
            .catch(error => {
                if (error.response && error.response.status === 422) {
                    // Captura a mensagem do erro
                    const errorMessage = error.response.data.message;

                    Swal.fire({
                        title: 'Erro',
                        text: errorMessage,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#d33'
                    });
                    // Aqui você pode tratar o erro conforme necessário
                } else {
                    Swal.fire({
                        title: 'Erro',
                        text: error,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#d33'
                    });
                }
            });
    };
    return (
        <div>
            <div className="btn btn-primary button" onClick={() => navigate('/admin-home/addclientes')} >
                <FaUserPlus size={18} />
                <span> Novo cliente</span>
            </div>
            <div className="divisor-container">
                {loading && (
                    <div className="product-container">
                        <Component.Shared.LoaderComponent overlay />
                    </div>
                )}

                <div className="divisor1">
                    <input
                        type="text"
                        placeholder="Filtrar por CNPJ/CPF ou nome do cliente"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <Component.Shared.ListaClienteCard onClienteClick={handleClienteClick} clientes={filteredClientes.slice(startIndex, endIndex)} habilita_cria_acesso={true} />

            <Component.Custom.Pagination
                currentPage={currentPage}
                totalItems={list.length}
                setCurrentPage={handlePageChange}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
            />
        </div>
    );
}

export default Clientes;